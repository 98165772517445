import {useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import Select from 'react-select'
const optionsItem = [
  {value: 'TITANIUMDIOXIDE', label: 'TITANIUM DIOXIDE'},
  {
    value: 'TIO2(TITANIUM DI OXIDE) (25 kg)',
    label: 'TIO2(TITANIUM DI OXIDE) (25 kg)',
  },
]
const optionsUnit = [
  {value: 'KGS', label: 'KGS'},
  {value: 'Liter', label: 'Liter'},
]
function Outward() {
  const [selectedItem, setSelectedItem] = useState()

  const handleItemChange = (selectedOption) => {
    setSelectedItem(selectedOption)
  }
  return (
    <Card className='card-custom'>
      <Card.Header>
        <div className='card-title'>
          <h4 className='card-title'>Outward Stock</h4>
        </div>
        <div className='card-toolbar'>
          <button type='button' className='btn btn-sm btn-light'>
            Action
          </button>
        </div>
      </Card.Header>

      <Form>
        <Card.Body>
          <Row>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label htmlFor='exampleInputText1'>Order Number</Form.Label>
                <Form.Control type='text' id='exampleInputText1' placeholder='Material Quantity' />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label htmlFor='exampleInputText1'>Material</Form.Label>
                <Select
                  defaultValue={selectedItem}
                  onChange={handleItemChange}
                  options={optionsItem}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label htmlFor='exampleInputText1'>Product Name</Form.Label>
                <Select
                  defaultValue={selectedItem}
                  onChange={handleItemChange}
                  options={optionsItem}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label htmlFor='exampleInputText1'>Material Quantity</Form.Label>
                <Form.Control type='text' id='exampleInputText1' placeholder='Material Quantity' />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label htmlFor='exampleInputText1'>Material Unit</Form.Label>
                <Select
                  defaultValue={selectedItem}
                  onChange={handleItemChange}
                  options={optionsUnit}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label htmlFor='exampleInputText1'>Taken Employee</Form.Label>
                <Form.Control type='text' id='exampleInputText1' placeholder='Employee Name' />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label htmlFor='exampleInputEmail3'>Order By</Form.Label>
                <Form.Control
                  type='email'
                  id='exampleInputEmail3'
                  defaultValue='Rutvik Patel'
                  placeholder='Enter Email'
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className='d-flex justify-content-end'>
          <Button type='button' variant='btn btn-primary' className='me-3'>
            <i className='bi bi-send fs-4 me-2'></i>
            Submit
          </Button>{' '}
          <Button type='button' variant='btn btn-danger'>
            <i className=' bi bi-x-circle  fs-4 me-2'></i> Cancel
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}

export default Outward
