// third-party
import {combineReducers} from 'redux'

// project-imports
import vendor from './vendorSlice'
import item from './itemSlice'
import po from './poSlice'
import inwardSlice from './inwardSlice'

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  vendor: vendor,
  item: item,
  po: po,
  inwardSlice: inwardSlice,
})

export default reducers
