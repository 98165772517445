import { FC, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import Cookies from "js-cookie";
import { App } from "../App";

const { PUBLIC_URL } = process.env;

const AppRoutes: FC = () => {
  const [currentUser, setCurrentUser] = useState(false);
  const token = Cookies.get("token");
  const username = Cookies.get("username");
  const role = Cookies.get("role");

  useEffect(() => {
    if (token && username && role) {
      setCurrentUser(true);
    }
    if (currentUser === true) {
      window.location.href = '/dashboard' 
    }
  }, [token, username, role]);

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="logout" element={<Logout />} />
          <Route path="auth/*" element={<AuthPage />} />
          <Route
            path="/*"
            element={
              currentUser ? (
                <PrivateRoutes />
              ) : (
                <Navigate to="/auth" replace />
              )
            }
          />
          <Route
            path="/"
            element={
              currentUser ? (
                <Navigate to="/dashboard" replace />
              ) : (
                <Navigate to="/auth" replace />
              )
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
