import axios from 'axios'
import Cookies from 'js-cookie'
import toast from 'react-hot-toast'

const API_URL = process.env.REACT_APP_API_URL
const handleUnauthorizedError = () => {
  Cookies.remove('token')
  Cookies.remove('username')
  Cookies.remove('role')
  window.location.href = '/auth/login' // This will redirect to the login page
}
export const addVendor = async (data) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.post(`${API_URL}/vendor/create`, data)

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    throw err
  }
}
export const getVendor = async () => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.get(`${API_URL}/vendor/get`)

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const deleteVendor = async (id) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.delete(`${API_URL}/vendor/delete/${id}`)
    toast.success('Item deleted successfully')
    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const showVendor = async (id) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.get(`${API_URL}/vendor/show/${id}`)

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const updateVendor = async (id, data) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.put(`${API_URL}/vendor/update/${id}`, data)
    toast.success('Updated Item Successfully')
    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
// Item start
export const addItem = async (data) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.post(`${API_URL}/item/create`, data)
    toast.success('Created Item Successfully')
    return response.data
  } catch (err) {
   if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const getItem = async () => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.get(`${API_URL}/item/get`)

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const deleteItem = async (id) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.delete(`${API_URL}/item/delete/${id}`)
    toast.success('Item deleted successfully')
    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const showItem = async (id) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.get(`${API_URL}/item/show/${id}`)

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const updateItem = async (id, data) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.put(`${API_URL}/item/update/${id}`, data)
    toast.success('Updated Item Successfully')
    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
// Item end
// po start
export const addPo = async (data) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.post(`${API_URL}/po/create`, data)

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    throw err
  }
}
export const getPo = async () => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.get(`${API_URL}/po/get`)

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const deletePo = async (id) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.delete(`${API_URL}/po/delete/${id}`)
    toast.success('Item deleted successfully')
    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const showPo = async (id) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.get(`${API_URL}/po/show/${id}`)

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const updatePo = async (id, data) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.put(`${API_URL}/po/update/${id}`, data)
    toast.success('Updated Item Successfully')
    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
// inward start
export const addInward = async (data) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.post(`${API_URL}/inward/create`, data)
    toast.success('Inward Created successfully')
    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    throw err
  }
}
export const getInward = async () => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.get(`${API_URL}/inward/get`)

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const deleteInward = async (id) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.delete(`${API_URL}/inward/delete/${id}`)
    toast.success('Inward deleted successfully')
    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const showInward = async (id) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.get(`${API_URL}/inward/show/${id}`)

    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
export const updateInward = async (id, data) => {
  try {
    const token = Cookies.get('token')
    axios.defaults.headers.common['Authorization'] = `${token}`
    const response = await axios.put(`${API_URL}/inward/update/${id}`, data)
    toast.success('Updated Inward Successfully')
    return response.data
  } catch (err) {
    if (err.response && err.response.status === 401) {
      handleUnauthorizedError()
      return
    }
    toast.error(err.response.data.message)
    throw err
  }
}
