import React, {useEffect, useState} from 'react'
import {Row, Col, Form, Button, Card} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {
  updateItemSuccess,
  useCreateItemItemMutation,
  useShowItemQuery,
} from '../../../redux/slice/itemSlice'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {showItem, updateItem} from '../../../redux/api'

function ItemPage({isCreateMode}) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm()
  const {mutate: createItem} = useCreateItemItemMutation()
  const {id} = useParams()
  const [itemData, setItemData] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    // Fetch item data only if isCreateMode is false and id is available
    if (!isCreateMode && id) {
      showItem(id)
        .then((data) => {
          setValue('itemName', data.itemName)
          setValue('itemCode', data.itemCode)
          setItemData(data) // Store item data in state
        })
        .catch((error) => {
          console.error('Error fetching item data:', error)
        })
    }
  }, [isCreateMode, id, setValue])
  const navigate = useNavigate()

  const onSubmit = async (formData) => {
    console.log('Code')
    try {
      if (isCreateMode) {
        await createItem(formData)
        navigate(`/item-list`)
        console.log('Codeif')
      } else {
        const response = await updateItem(id, formData)
        dispatch(updateItemSuccess({response, formData}))
        navigate(`/item-list`)
      }
      // Optionally, handle success state or navigate to another page
    } catch (error) {
      console.log(error.response.data.message, 'error')
      // Handle error state
    }
  }
  const cancel = () => {
    navigate(`/item-list`)
  }
  return (
    <>
      <Card className='card-custom'>
        <Card.Header>
          <div className='card-title'>
            <h4 className='card-title'>{isCreateMode ? 'Add Item' : 'Edit Item'}</h4>
          </div>
          <div className='card-toolbar'>
            <Link to='/item-list' className='btn btn-primary'>
              <i className='fa-solid fa-arrow-left'></i>
              Item List
            </Link>
          </div>
        </Card.Header>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row>
              <Col lg={3}>
                <Form.Group className='form-group'>
                  <Form.Label>Item Name</Form.Label>
                  <Form.Control
                    type='text'
                    {...register('itemName', {required: true})}
                    placeholder='Enter Name'
                  />
                  {errors.itemName && <span className='text-danger'>This field is required</span>}
                </Form.Group>
              </Col>
              {!isCreateMode ?
                <Col lg={3}>
                <Form.Group className='form-group'>
                  <Form.Label>Item Code</Form.Label>
                  <Form.Control
                    type='text'
                    {...register('itemCode', {required: true})}
                    placeholder='Enter Code'
                  />
                  {errors.itemCode && <span className='text-danger'>This field is required</span>}
                </Form.Group>
              </Col>: null}
             
            </Row>
          </Card.Body>
          <Card.Footer className='d-flex justify-content-end'>
            <Button type='submit' variant='btn btn-primary' className='me-3'>
              <i className='bi bi-send fs-4 me-2'></i>
              {isCreateMode ? 'Submit' : 'Update'}
            </Button>{' '}
            <Button type='button' variant='btn btn-danger' onClick={() => cancel()}>
              <i className=' bi bi-x-circle  fs-4 me-2'></i> Cancel
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </>
  )
}

export default ItemPage
