import {Button, Card, Col, Form, Row} from 'react-bootstrap'

function CreateProduct() {
  return (
    <>
      {' '}
      <Card className='card-custom'>
        <Card.Header>
          <div className='card-title'>
            <h4 className='card-title'>Create Product</h4>
          </div>
          <div className='card-toolbar'>
            <button type='button' className='btn btn-sm btn-light'>
              Action
            </button>
          </div>
        </Card.Header>

        <Form>
          <Card.Body>
            <Row>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label htmlFor='exampleInputText1'>Product Name</Form.Label>
                  <Form.Control type='text' id='exampleInputText1' placeholder='Product Name' />
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label htmlFor='exampleInputText1'>Product Code</Form.Label>
                  <Form.Control type='text' id='exampleInputText1' placeholder='Product Code' />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className='d-flex justify-content-end'>
            <Button type='button' variant='btn btn-primary' className='me-3'>
              <i className='bi bi-send fs-4 me-2'></i>
              Submit
            </Button>{' '}
            <Button type='button' variant='btn btn-danger'>
              <i className=' bi bi-x-circle  fs-4 me-2'></i> Cancel
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </>
  )
}

export default CreateProduct
