// third-party
import {configureStore} from '@reduxjs/toolkit'
import {useDispatch as useAppDispatch, useSelector as useAppSelector} from 'react-redux'

// project-imports
import reducers from './slice'

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducers,
})

const {dispatch} = store

const useDispatch = () => useAppDispatch()
const useSelector = useAppSelector

export {store, dispatch, useSelector, useDispatch}
