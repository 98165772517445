import {createSlice} from '@reduxjs/toolkit'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
  addInward,
  deleteInward,
  getInward,
  showInward,
  updateInward,
} from '../api'

export const InwardSlice = createSlice({
  name: 'inward',
  initialState: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  reducers: {
    setInwardResponse(state, action) {
      state.response = action.payload
    },
    setLoading(state, action) {
      state.isLoading = action.payload
    },
    createInwardStart(state) {
      state.status = 'loading'
      state.error = null
    },
    createInwardSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    createInwardFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    deleteInwardStart(state) {
      state.status = 'loading'
      state.error = null
    },
    deleteInwardSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    deleteInwardFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    updateInwardStart(state) {
      state.status = 'loading'
      state.error = null
    },
    updateInwardSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    updateInwardFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    showInwardStart(state) {
      state.status = 'loading'
      state.error = null
    },
    showInwardSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    showInwardFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
  },
})

export const {
  setInwardResponse,
  setLoading,
  createInwardStart,
  createInwardSuccess,
  createInwardFailure,
  deleteInwardStart,
  deleteInwardSuccess,
  deleteInwardFailure,
  updateInwardStart,
  updateInwardSuccess,
  updateInwardFailure,
  showInwardStart,
  showInwardSuccess,
  showInwardFailure,
} = InwardSlice.actions

export const useCreateInwardFMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(addInward, {
    onSuccess: () => {
      queryClient.invalidateQueries('inward')
    },
  })
}
export const useInwards = () => {
  return useQuery('inward', () => getInward())
}

export const useDeleteInwardMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteInward, {
    onSuccess: () => {
      queryClient.invalidateQueries('inward')
    },
  })
}
export const useUpdateInwardMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((data) => updateInward(data.id, data.inwardData), {
    onSuccess: () => {
      queryClient.invalidateQueries('inward')
    },
  })
}

export const useShowInwardQuery = (id) => {
  return useQuery(['showinward', id], () => showInward(id))
}
export const selectResponseInward = (state) => state.vendor.response
export const selectIsLoading = (state) => state.vendor.isLoading

export default InwardSlice.reducer
