import {Card, Row, Col} from 'react-bootstrap'
import {DataTable, Column, InputText, Dialog, Button} from 'primereact'
import {Link, useNavigate} from 'react-router-dom'
import {deletePo, getPo} from '../../../redux/api'
import {useCallback, useEffect, useState} from 'react'

function POList() {
  const [tableData, setTableData] = useState([])
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [selectedItem, setSelectedItem] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [filters, setFilters] = useState({
    global: {
      value: '',
    },
  })
  const fetchTableData = useCallback(async () => {
    try {
      const res = await getPo()
      setTableData(res)
    } catch (error) {
      console.error('Error fetching table data:', error)
    }
  }, [])

  useEffect(() => {
    fetchTableData()
  }, [fetchTableData])

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = filters ? {...filters} : {global: {value: ''}}
    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const handleShow = (rowData) => {
    setSelectedItem(rowData)
    setShowModal(true)
  }

  const handleDelete = async (rowData) => {
    try {
      await deletePo(rowData.id)
      setTableData(tableData.filter((item) => item.id !== rowData.id))
    } catch (error) {
      console.error('Error deleting item:', error)
    }
  }
  const navigate = useNavigate()
  const handleEdit = (rowData) => {
    const itemId = rowData.id
    navigate(`/po-list/edit-po/${itemId}`)
    setSelectedItem(rowData)
  }
  const header = (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='fa-solid fa-magnifying-glass'></i>
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder='Keyword Search'
        />
      </span>
    </div>
  )

  // Apply filtering logic to tableData
  const filteredData = tableData.filter((item) => {
    return (
      item.vendor.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
      item.poNumber.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
      item.itemName.toLowerCase().includes(globalFilterValue.toLowerCase())
    )
  })
  return (
    <Card className='card-custom'>
      <Card.Header>
        <div className='card-title'>
          <h4 className='card-title'>PO List</h4>
        </div>
        <div className='card-toolbar'>
          <div className='card-toolbar'>
            <Link to='/po-list/create-po' className='btn btn-primary'>
              <i className='fa-solid fa-plus'></i>
              Create PO
            </Link>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <DataTable
          value={filteredData}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{minWidth: '50rem'}}
          header={header}
        >
          <Column field='poNumber' sortable header='Po Number'></Column>
          <Column field='vendor' sortable header='Vendor Name'></Column>
          <Column field='itemName' sortable header='Item Name'></Column>
          <Column field='qty' sortable header='Qty'></Column>
          <Column field='unit' sortable header='Unit'></Column>
          <Column field='rate' sortable header='Rate'></Column>
          <Column field='totalAmount' sortable header='Total Amount'></Column>
          <Column field='paymentName' sortable header='Payment Method'></Column>
          <Column field='purchaseDate' sortable header='Purchase Date'></Column>
          <Column field='deliveryDate' sortable header='Delivery Date'></Column>

          <Column header='Actions' body={actionTemplate}></Column>
        </DataTable>
      </Card.Body>
      <Dialog
        visible={showModal}
        maximizable
        style={{width: '50vw'}}
        onHide={() => setShowModal(false)}
      >
        <div className='modal-content'>
          <h4>Vendor Details</h4>
          {selectedItem && (
            <>
              <Row>
                <Col md={4}>
                  <p>
                    <strong>Vendor Name:</strong> {selectedItem.vendor}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Po Number:</strong> {selectedItem.poNumber}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Purchase Date:</strong> {selectedItem.purchaseDate}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Delivery Date:</strong> {selectedItem.deliveryDate}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Order By:</strong> {selectedItem.orderBy}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Email:</strong> {selectedItem.email}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Mobile:</strong> {selectedItem.mobile}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Item Code:</strong> {selectedItem.itemCode}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Item Name:</strong> {selectedItem.itemName}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Qty:</strong> {selectedItem.qty}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Unit:</strong> {selectedItem.unit}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Rate:</strong> {selectedItem.rate}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Discount:</strong> {selectedItem.discount}%
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Discount Amt:</strong> {selectedItem.discountAmt}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>CGST:</strong> {selectedItem.cgst}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>SGST:</strong> {selectedItem.sgst}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>IGST:</strong> {selectedItem.igst}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Total Amount:</strong> {selectedItem.totalAmount}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Payment Name:</strong> {selectedItem.paymentName}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Dialog>
    </Card>
  )
  // Custom template for Action column
  function actionTemplate(rowData) {
    return (
      <div className='flex'>
        <Button
          className='btn btn-light-primary me-2 px-2 py-1'
          onClick={() => handleEdit(rowData)}
        >
          <i className='fa-solid fa-pen-to-square p-0'></i>
        </Button>
        <Button className='btn btn-light-info me-2 px-2 py-1' onClick={() => handleShow(rowData)}>
          <i className='fa-regular fa-eye p-0'></i>
        </Button>
        <Button
          className='btn btn-light-danger px-2 py-1px-2 py-1'
          onClick={() => handleDelete(rowData)}
        >
          <i className='fa-regular fa-trash-can p-0'></i>
        </Button>
      </div>
    )
  }
}

export default POList
