import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { MenuTestPage } from "../pages/MenuTestPage";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import BuilderPageWrapper from "../pages/layout-builder/BuilderPageWrapper";
import CreateVendor from "../pages/purchase/createVendor";
import InwardStock from "../pages/purchase/inwardStock";
import ItemPage from "../pages/purchase/Items";
import VendorList from "../pages/purchase/vendorList";
import InwardList from "../pages/purchase/inwardStockList";
import Outward from "../pages/Outward";
import OutwardList from "../pages/Outward/list";
import CreateProduct from "../pages/Outward/createProduct";
import ProductList from "../pages/Outward/productList";
import ItemList from "../pages/purchase/itemList";
import PurchaseOrderPage from "../pages/purchase/PurchaseOrder";
import POList from "../pages/purchase/purchaseOrderList";

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WizardsPage = lazy(() => import("../modules/wizards/WizardsPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
  const ChatPage = lazy(() => import("../modules/apps/chat/ChatPage"));
  const UsersPage = lazy(
    () => import("../modules/apps/user-management/UsersPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route path="builder" element={<BuilderPageWrapper />} />
        <Route path="menu-test" element={<MenuTestPage />} />
        <Route path="vendor-list/create-vendor" element={<CreateVendor isCreateMode={true} />} />
        <Route path="vendor-list/edit-vendor/:id" element={<CreateVendor isCreateMode={false} />} />
        <Route path="inward-list/create-inward" element={<InwardStock isCreateMode={true} />} />
        <Route path="inward-list/edit-inward/:id" element={<InwardStock isCreateMode={false} />} />
        <Route path="item-list/add-item" element={<ItemPage isCreateMode={true} />} />
        <Route path="/item-list/edit-item/:id" element={<ItemPage isCreateMode={false} />} />
        <Route path="item-list" element={<ItemList />} />
        <Route path="vendor-list" element={<VendorList />} />
        <Route path="inward-list" element={<InwardList />} />
        <Route path="create-outward" element={<Outward />} />
        <Route path="outward-list" element={<OutwardList />} />
        <Route path="createProduct" element={<CreateProduct />} />
        <Route path="productList" element={<ProductList />} />
        <Route path="po-list/create-po" element={<PurchaseOrderPage isCreateMode={true} />} />
        <Route path="po-list/edit-po/:id" element={<PurchaseOrderPage isCreateMode={false} />} />
        <Route path="po-list" element={<POList />} />
        {/* Lazy Modules */}
        <Route
          path="crafted/pages/profile/*"
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/pages/wizards/*"
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/widgets/*"
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path="crafted/account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path="apps/user-management/*"
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor
    },
    barThickness: 1,
    shadowBlur: 5
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
