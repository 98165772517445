import {createSlice} from '@reduxjs/toolkit'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {addPo, deletePo, getPo, showPo, updatePo} from '../api'

export const PoSlice = createSlice({
  name: 'po',
  initialState: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  reducers: {
    setPoResponse(state, action) {
      state.response = action.payload
    },
    setLoading(state, action) {
      state.isLoading = action.payload
    },
    createPoStart(state) {
      state.status = 'loading'
      state.error = null
    },
    createPoSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    createPoFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    deletePoStart(state) {
      state.status = 'loading'
      state.error = null
    },
    deletePoSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    deletePoFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    updatePoStart(state) {
      state.status = 'loading'
      state.error = null
    },
    updatePoSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    updatePoFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    showPoStart(state) {
      state.status = 'loading'
      state.error = null
    },
    showPoSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    showPoFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
  },
})

export const {
  setPoResponse,
  setLoading,
  createPoStart,
  createPoSuccess,
  createPoFailure,
  deletePoStart,
  deletePoSuccess,
  deletePoFailure,
  updatePoStart,
  updatePoSuccess,
  updatePoFailure,
  showPoStart,
  showPoSuccess,
  showPoFailure,
} = PoSlice.actions

export const useCreatePoMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(addPo, {
    onSuccess: () => {
      queryClient.invalidateQueries('Po')
    },
  })
}
export const usePos = () => {
  return useQuery('Po', () => getPo())
}

export const useDeletePoMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(deletePo, {
    onSuccess: () => {
      queryClient.invalidateQueries('Po')
    },
  })
}
export const useUpdatePoMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((data) => updatePo(data.id, data.PoData), {
    onSuccess: () => {
      queryClient.invalidateQueries('Po')
    },
  })
}

export const useShowPoQuery = (id) => {
  return useQuery(['showPo', id], () => showPo(id))
}
export const selectResponsePo = (state) => state.Po.response
export const selectIsLoading = (state) => state.Po.isLoading

export default PoSlice.reducer
