import {createSlice} from '@reduxjs/toolkit'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {addVendor, deleteVendor, getVendor, showVendor, updateVendor} from '../api'

export const VendorSlice = createSlice({
  name: 'vendor',
  initialState: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  reducers: {
    setVendorResponse(state, action) {
      state.response = action.payload
    },
    setLoading(state, action) {
      state.isLoading = action.payload
    },
    createVendorStart(state) {
      state.status = 'loading'
      state.error = null
    },
    createVendorSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    createVendorFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    deleteVendorStart(state) {
      state.status = 'loading'
      state.error = null
    },
    deleteVendorSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    deleteVendorFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    updateVendorStart(state) {
      state.status = 'loading'
      state.error = null
    },
    updateVendorSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    updateVendorFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    showVendorStart(state) {
      state.status = 'loading'
      state.error = null
    },
    showVendorSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    showVendorFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
  },
})

export const {
  setVendorResponse,
  setLoading,
  createVendorStart,
  createVendorSuccess,
  createVendorFailure,
  deleteVendorStart,
  deleteVendorSuccess,
  deleteVendorFailure,
  updateVendorStart,
  updateVendorSuccess,
  updateVendorFailure,
  showVendorStart,
  showVendorSuccess,
  showVendorFailure,
} = VendorSlice.actions

export const useCreateVendorMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(addVendor, {
    onSuccess: () => {
      queryClient.invalidateQueries('vendor')
    },
  })
}
export const useVendors = () => {
  return useQuery('vendor', () => getVendor())
}

export const useDeleteVendorMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteVendor, {
    onSuccess: () => {
      queryClient.invalidateQueries('vendor')
    },
  })
}
export const useUpdateVendorMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((data) => updateVendor(data.id, data.vendorData), {
    onSuccess: () => {
      queryClient.invalidateQueries('vendor')
    },
  })
}

export const useShowVendorQuery = (id) => {
  return useQuery(['showvendor', id], () => showVendor(id))
}
export const selectResponseVendor = (state) => state.vendor.response
export const selectIsLoading = (state) => state.vendor.isLoading

export default VendorSlice.reducer
