import {Button, Card, Col, Form, FormCheck, Row} from 'react-bootstrap'
import Select from 'react-select'
import {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useVendors} from '../../../redux/slice/vendorSlice'
import {useItems} from '../../../redux/slice/itemSlice'
import {useForm} from 'react-hook-form'
import {
  createInwardStart,
  updateInwardSuccess,
  useCreateInwardFMutation,
} from '../../../redux/slice/inwardSlice'
import {showInward, updateInward} from '../../../redux/api'
import {useDispatch} from 'react-redux'

function InwardStock({isCreateMode}) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
  } = useForm()
  const {id} = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {mutate: createInward} = useCreateInwardFMutation()
  const {data: vendors} = useVendors()
  const {data: items} = useItems()
  const [vendorName, setVendorName] = useState()
  const [itemName, setItemName] = useState('')
  const [itemList, setItemList] = useState('')
  const [vendorList, setVendorList] = useState()
  useEffect(() => {
    if (vendors) {
      const updatedVendorNames = vendors.map((vendor) => ({
        value: vendor.nameOfCompany, // Assuming each vendor object has an id field
        label: vendor.nameOfCompany, // Change this to the actual field name containing vendor company name
      }))
      setVendorList(updatedVendorNames)
    }
  }, [vendors])
  useEffect(() => {
    if (items) {
      const updatedItemNames = items.map((item) => ({
        value: item.itemName,
        label: item.itemName,
      }))
      setItemList(updatedItemNames)
    }
  }, [items])
  useEffect(() => {
    // Fetch item data only if isCreateMode is false and id is available
    if (!isCreateMode && id) {
      showInward(id)
        .then((data) => {
          Object.keys(data).forEach((key) => {
            setValue(key, data[key])
            setItemName({value: data.itemName, label: data.itemName})
            setVendorName({value: data.vendor, label: data.vendor})
          })
        })
        .catch((error) => {
          console.error('Error fetching item data:', error)
        })
    }
  }, [isCreateMode, id, setValue])
  const handleVendorChange = (selectedOption) => {
    const vendorName = selectedOption ? selectedOption.value : null
    setVendorName(selectedOption)
    setValue('vendor', vendorName)
  }
  const handleItemChange = (selectedOption) => {
    const itemNameValue = selectedOption ? selectedOption.value : null
    setItemName(selectedOption)
    setValue('itemName', itemNameValue)
  }

  const quantityReceived = parseFloat(watch('quantityReceived')) || 0
  const unitCost = parseFloat(watch('unitCost')) || 0
  useEffect(() => {
    const calculateTotalCost = () => {
      const subTotal = quantityReceived * unitCost
      setValue('totalCost', subTotal)
    }

    calculateTotalCost()
  }, [quantityReceived, unitCost])

  const onSubmit = async (data) => {
    try {
      if (isCreateMode) {
        dispatch(createInwardStart())
        await createInward(data)
        navigate(`/inward-list`)
      } else {
        const response = await updateInward(id, data)
        dispatch(updateInwardSuccess({response, data}))
        navigate(`/inward-list`)
      }
      // Optionally, handle success state or navigate to another page
    } catch (error) {
      console.error(error)
      // Handle error state
    }
  }
  const cancel = () => {
    navigate(`/vendor-list`)
  }
  return (
    <Card className='card-custom'>
      <Card.Header>
        <div className='card-title'>
          <h4 className='card-title'>{isCreateMode ? 'Add Inward Stock' : 'Edit Inward Stock'}</h4>
        </div>
        <div className='card-toolbar'>
          <Link to='/inward-list' className='btn btn-primary'>
            <i className='fa-solid fa-arrow-left'></i>
            Inward Stock List
          </Link>
        </div>
      </Card.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Body>
          <Row>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Item Name</Form.Label>
                <Select
                  options={itemList}
                  value={itemName}
                  onChange={handleItemChange}
                  name='itemName'
                />
                {errors.itemName && <span className='text-danger'>{errors.itemName.message}</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Quantity Received</Form.Label>
                <Form.Control
                  type='text'
                  {...register('quantityReceived', {
                    required: 'Quantity Received is required',
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Please enter only numbers',
                    },
                  })}
                  placeholder='Enter Quantity Received'
                />
                {errors.quantityReceived && (
                  <span className='text-danger'>{errors.quantityReceived.message}</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Unit Cost</Form.Label>
                <Form.Control
                  type='text'
                  {...register('unitCost', {
                    required: 'Unit Cost is required',
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Please enter only numbers',
                    },
                  })}
                  placeholder='Enter Unit Cost'
                />
                {errors.unitCost && <span className='text-danger'>{errors.unitCost.message}</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Total Cost</Form.Label>
                <Form.Control
                  type='text'
                  {...register('totalCost', {
                    required: 'Total Cost Received is required',
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Please enter only numbers',
                    },
                  })}
                  placeholder='Enter Total Cost'
                />
                {errors.totalCost && <span>{errors.unitCost.message}</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Vendor Name</Form.Label>
                <Select
                  onChange={handleVendorChange}
                  options={vendorList}
                  value={vendorName}
                  name='vendor'
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Date Received</Form.Label>
                <Form.Control
                  type='date'
                  defaultValue='2019-12-18'
                  placeholder='Enter Date Received'
                  {...register('date', {required: 'Date Received is required'})}
                />
                {errors.date && <span className='text-danger'>{errors.unitCost.message}</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Storage Location</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Storage Location'
                  {...register('storageLocation', {required: 'Storage Location is required'})}
                />
                {errors.storageLocation && (
                  <span className='text-danger'>{errors.unitCost.message}</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Invoice Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Invoice Number'
                  {...register('invoiceNumber', {
                    required: 'Invoice Number is required',
                    pattern: {
                      value: /^[0-9]*$/,
                      message: 'Please enter only numbers',
                    },
                  })}
                />
                {errors.invoiceNumber && (
                  <span className='text-danger'>{errors.unitCost.message}</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Condition</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Condition'
                  {...register('condition', {required: 'Condition is required'})}
                />
                {errors.condition && <span className='text-danger'>{errors.unitCost.message}</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Received By</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name'
                  {...register('receivedBy', {required: 'Received By is required'})}
                />
                {errors.receivedBy && (
                  <span className='text-danger'>{errors.unitCost.message}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className='d-flex justify-content-end'>
          <Button type='submit' variant='btn btn-primary' className='me-3'>
            <i className='bi bi-send fs-4 me-2'></i>
            {isCreateMode ? 'Submit' : 'Update'}
          </Button>{' '}
          <Button type='button' variant='btn btn-danger' onClick={() => cancel()}>
            <i className=' bi bi-x-circle  fs-4 me-2'></i> Cancel
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}

export default InwardStock
