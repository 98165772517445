import {Button, Card, Col, Form, FormCheck, Row} from 'react-bootstrap'
import Select from 'react-select'
import countriesData from '../../../data/country-by-currency-code.json'
import {useEffect, useState} from 'react'
import {useForm} from 'react-hook-form'
import {
  createVendorStart,
  updateVendorSuccess,
  useCreateVendorMutation,
} from '../../../redux/slice/vendorSlice'
import {useDispatch} from 'react-redux'
import {Link, useParams, useNavigate} from 'react-router-dom'
import {showVendor, updateVendor} from '../../../redux/api'
import {accountType} from '../../../data/other'

function CreateVendor({isCreateMode}) {
  const dispatch = useDispatch()
  const optionsCountry = countriesData.map((country) => ({
    value: country.country,
    label: country.country,
  }))
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm()
  const {id} = useParams()
  const navigate = useNavigate()
  const {mutate: createVendor} = useCreateVendorMutation()
  const [selectedCountry, setSelectedCountry] = useState(null)
  const [selectedCurrency, setSelectedCurrency] = useState('')
  const [accountTypeData, setAccounTypeData] = useState()
  const [itemData, setItemData] = useState(null)

  const handleCountryChange = (selectedOption) => {
    const selectedCountryData = countriesData.find(
      (country) => country.country === selectedOption.value
    )
    const itemNameValue = selectedOption ? selectedOption.value : null
    setSelectedCountry(selectedOption)
    setValue('country', itemNameValue)
    if (selectedCountryData) {
      setSelectedCurrency(selectedCountryData.currency_code || '')
    } else {
      setSelectedCurrency('')
    }
  }
  useEffect(() => {
    setSelectedCurrency(itemData?.currency_code)
  }, [itemData])
  useEffect(() => {
    // Fetch item data only if isCreateMode is false and id is available
    if (!isCreateMode && id) {
      showVendor(id)
        .then((data) => {
          Object.keys(data).forEach((key) => {
            setValue(key, data[key])
            setSelectedCountry({value: data.country, label: data.country})
            setAccounTypeData({value: data.accountType, label: data.accountType})
            setItemData(data)
          })
        })
        .catch((error) => {
          console.error('Error fetching item data:', error)
        })
    }
  }, [isCreateMode, id, setValue])

  const handleAccountType = (selectedOption) => {
    setAccounTypeData(selectedOption)
    setValue('accountType', selectedOption ? selectedOption.value : null)
  }

  const onSubmit = async (data) => {
    try {
      if (isCreateMode) {
        dispatch(createVendorStart())
        await createVendor(data)
        navigate(`/vendor-list`)
      } else {
        const response = await updateVendor(id, data)
        dispatch(updateVendorSuccess({response, data}))
        navigate(`/vendor-list`)
      }
      // Optionally, handle success state or navigate to another page
    } catch (error) {
      console.error(error)
      // Handle error state
    }
  }
  const cancel = () => {
    navigate(`/vendor-list`)
  }
  return (
    <Card className='card-custom'>
      <Card.Header>
        <div className='card-title'>
          <h4 className='card-title'>{isCreateMode ? 'Create Vendor' : 'Edit Vendor'}</h4>
        </div>
        <div className='card-toolbar'>
          <Link to='/vendor-list' className='btn btn-primary'>
            <i className='fa-solid fa-arrow-left'></i>
            Vendor List
          </Link>
        </div>
      </Card.Header>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card.Body>
          <Row>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Name of Company</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue=''
                  placeholder='Enter Name of Company'
                  {...register('nameOfCompany', {required: true})}
                />
                {errors.nameOfCompany && (
                  <span className='text-danger'>Name of Company is required</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Vendor Name</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue=''
                  placeholder='Enter Name'
                  {...register('vendorName', {required: true})}
                />
                {errors.vendorName && <span className='text-danger'>Vendor Name is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Survey No/ Office No</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue=''
                  placeholder='Survey No/ Office No'
                  {...register('surveyNo', {required: true})}
                />
                {errors.surveyNo && (
                  <span className='text-danger'>Survey No/ Office No is required</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Landmark</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue=''
                  placeholder='Landmark'
                  {...register('landmark', {required: true})}
                />
                {errors.landmark && <span className='text-danger'>Landmark is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Village</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue=''
                  placeholder='Village'
                  {...register('village', {required: true})}
                />
                {errors.village && <span className='text-danger'>Village is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Taluka</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue=''
                  placeholder='Taluka'
                  {...register('taluka', {required: true})}
                />
                {errors.taluka && <span className='text-danger'>Taluka is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>District</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue=''
                  placeholder='District'
                  {...register('district', {required: true})}
                />
                {errors.district && <span className='text-danger'>District is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>State</Form.Label>
                <Form.Control
                  type='text'
                  defaultValue=''
                  placeholder='State'
                  {...register('state', {required: true})}
                />
                {errors.state && <span className='text-danger'>State is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Postal Code</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Postal Code'
                  {...register('postalCode', {required: true})}
                />
                {errors.postalCode && <span className='text-danger'>Postal Code is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Country</Form.Label>
                <Select
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  options={optionsCountry}
                  name='country'
                  // {...register('country', {required: true})}
                />
                {errors.country && <span className='text-danger'>Country is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Currency</Form.Label>
                <Form.Control
                  type='text'
                  value={selectedCurrency}
                  readOnly
                  {...register('currency', {required: true})}
                />
                {errors.currency && <span className='text-danger'>Currency is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Company Registration #</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Company Registration '
                  {...register('companyRegistration', {required: true})}
                />
                {errors.companyRegistration && (
                  <span className='text-danger'>Company Registration is required</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>GSTIN No #</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter GSTIN No'
                  {...register('gst', {required: true})}
                />
                {errors.gst && <span className='text-danger'>GSTIN No is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Udyog Aadhaar</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Udyog Aadhaar'
                  {...register('udyogAadhaar', {required: true})}
                />
                {errors.udyogAadhaar && (
                  <span className='text-danger'>Udyog Aadhaar is required</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Gumasta Dhara</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Gumasta Dhara'
                  {...register('gumastaDhara', {required: true})}
                />
                {errors.gumastaDhara && (
                  <span className='text-danger'>Gumasta Dhara is required</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>PAN #</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter PAN'
                  {...register('pan', {required: true})}
                />
                {errors.pan && <span className='text-danger'>PAN is required</span>}
              </Form.Group>
            </Col>

            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  {...register('email', {required: true})}
                />
                {errors.email && <span className='text-danger'>Email is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label htmlFor='exampleInputphone'>Tel</Form.Label>
                <Form.Control
                  type='tel'
                  placeholder='Enter Tele'
                  {...register('tel', {required: true})}
                />
                {errors.tel && <span className='text-danger'>Postal Code is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label htmlFor='exampleInputphone'>Website</Form.Label>
                <Form.Control
                  type='url'
                  placeholder='Enter Website'
                  {...register('website', {required: true})}
                />
                {errors.website && <span className='text-danger'>Website is required</span>}
              </Form.Group>
            </Col>

            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Contact Person Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Person Name'
                  {...register('contanctPersonName', {required: true})}
                />
                {errors.contanctPersonName && (
                  <span className='text-danger'>Contact Person Name is required</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Contact Person Designation</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Person Designation'
                  {...register('contactPersonDesignation', {required: true})}
                />
                {errors.contactPersonDesignation && (
                  <span className='text-danger'>Contact Person Designation is required</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Contact Person Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Person Number'
                  {...register('contactPersonNumber', {required: true})}
                />
                {errors.contactPersonDesignation && (
                  <span className='text-danger'>Contact Person Number is required</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col lg={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Nature of Business (Please Tick any One)</Form.Label>
                <fieldset className='mb-3 d-flex justify-content-between'>
                  <Form.Check className='me-1'>
                    <FormCheck.Input type='radio' className='' {...register('natureOfBusiness')} />
                    <FormCheck.Label className='' htmlFor='Manufacturing'>
                      Manufacturing
                    </FormCheck.Label>
                  </Form.Check>
                  <Form.Check className='me-1'>
                    <FormCheck.Input type='radio' className='' {...register('natureOfBusiness')} />
                    <FormCheck.Label className='' htmlFor='Trader'>
                      Trader
                    </FormCheck.Label>
                  </Form.Check>
                  <Form.Check className='me-1'>
                    <FormCheck.Input type='radio' className='' {...register('natureOfBusiness')} />
                    <FormCheck.Label className='' htmlFor='Agent'>
                      Agent
                    </FormCheck.Label>
                  </Form.Check>
                  <Form.Check className='me-1'>
                    <FormCheck.Input type='radio' className='' {...register('natureOfBusiness')} />
                    <FormCheck.Label className='' htmlFor='Dealer'>
                      Dealer
                    </FormCheck.Label>
                  </Form.Check>
                </fieldset>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className='mb-3'>
                <Form.Label>Year Of Establishment</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Year Of Establishment'
                  {...register('yearOfEstablishment')}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Name of Account Holder</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Name of Account Holder'
                  {...register('accountHolder', {required: true})}
                />
                {errors.accountHolder && (
                  <span className='text-danger'>Name of Account Holder is required</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Bank Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Bank Name'
                  {...register('bankName', {required: true})}
                />
                {errors.bankName && <span className='text-danger'>Bank Name is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Branch Name</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Bank address'
                  {...register('bankAddress', {required: true})}
                />
                {errors.bankAddress && (
                  <span className='text-danger'>Bank address is required</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>IFSC</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter IFSC'
                  {...register('ifsc', {required: true})}
                />
                {errors.ifsc && <span className='text-danger'>IFSC is required</span>}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Bank Account Number</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Enter Account Number'
                  {...register('bankAccountNumber', {required: true})}
                />
                {errors.bankAccountNumber && (
                  <span className='text-danger'>Bank Account Number is required</span>
                )}
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group className='mb-3'>
                <Form.Label>Account Type</Form.Label>
                <Select
                  value={accountTypeData}
                  onChange={handleAccountType}
                  options={accountType}
                  name='accountType'
                  // {...register("accountType", { required: true })}
                />
                {errors.bankAccountNumber && (
                  <span className='text-danger'>Bank Account Number is required</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className='d-flex justify-content-end'>
          <Button type='submit' variant='btn btn-primary' className='me-3'>
            <i className='bi bi-send fs-4 me-2'></i>
            {isCreateMode ? 'Submit' : 'Update'}
          </Button>{' '}
          <Button type='button' variant='btn btn-danger' onClick={() => cancel()}>
            <i className=' bi bi-x-circle  fs-4 me-2'></i> Cancel
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}

export default CreateVendor
