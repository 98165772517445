import React, {useEffect, useState} from 'react'
import {Row, Col, Form, Button, Card, InputGroup} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {Link, useParams, useNavigate} from 'react-router-dom'
import Select from 'react-select'
import {useDispatch} from 'react-redux'
import {createPoStart, updatePoSuccess, useCreatePoMutation} from '../../../redux/slice/poSlice'
import {showPo, updatePo} from '../../../redux/api'
import {useVendors} from '../../../redux/slice/vendorSlice'
import {optionsPayment, optionsUnit} from '../../../data/other'
import {useItems} from '../../../redux/slice/itemSlice'

function PurchaseOrderPage({isCreateMode}) {
  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
  } = useForm()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {id} = useParams()
  const {data: vendors} = useVendors()
  const {data: items} = useItems()
  const [vendorName, setVendorName] = useState()
  const [itemCode, setItemCode] = useState()
  const {mutate: createPo} = useCreatePoMutation()
  const [selectedVendor, setSelectedVendor] = useState(null)
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [itemName, setItemName] = useState('')
  const [paymentName, setPaymentName] = useState('')
  const [getData, setGetData] = useState()
  useEffect(() => {
    if (vendors) {
      const updatedVendorNames = vendors.map((vendor) => ({
        value: vendor.nameOfCompany, // Assuming each vendor object has an id field
        label: vendor.nameOfCompany, // Change this to the actual field name containing vendor company name
      }))
      setVendorName(updatedVendorNames)
    }
  }, [vendors])
  const optionsCode =
    items &&
    items.map((country) => ({
      value: country.itemCode,
      label: country.itemCode,
    }))
  const handleVendorChange = (selectedOption) => {
    setSelectedVendor(selectedOption)
    const vendorValue = selectedOption ? selectedOption.value : null
    setValue('vendor', vendorValue)
  }
  const handleItemChange = (selectedOption) => {
    setItemCode(selectedOption)
    const selectedItemName = items.find((country) => country.itemCode === selectedOption.value)
    const itemNameValue = selectedOption ? selectedOption.value : null
    setValue('itemName', itemNameValue.value)
    if (selectedItemName) {
      setItemName(selectedItemName.itemName)
    } else {
      setItemName('')
    }
  }
  useEffect(() => {
    setItemName(getData?.itemName)
  }, [getData])
  const handlePaymentChange = (selectedOption) => {
    setPaymentName(selectedOption)
    setValue('paymentName', selectedOption ? selectedOption.value : null)
  }
  const handleUnit = (selectedOption) => {
    setSelectedUnit(selectedOption)
    setValue('unit', selectedOption ? selectedOption.value : null)
  }
  useEffect(() => {
    // Fetch item data only if isCreateMode is false and id is available
    if (!isCreateMode && id) {
      showPo(id)
        .then((data) => {
          Object.keys(data).forEach((key) => {
            setValue(key, data[key])
            setSelectedVendor({value: data.vendor, label: data.vendor})
            setPaymentName({value: data.paymentName, label: data.paymentName})
            setSelectedUnit({value: data.unit, label: data.unit})
            setItemCode({value: data.itemCode, label: data.itemCode})
            setGetData(data)
          })
        })
        .catch((error) => {
          console.error('Error fetching item data:', error)
        })
    }
  }, [isCreateMode, id, setValue])
  const qty = parseFloat(watch('qty')) || 0
  const rate = parseFloat(watch('rate')) || 0
  const subTotal = qty * rate
  const discount = parseFloat(watch('discount')) || 0
  const discountAmt = (subTotal * discount) / 100
  const cgstPercentage = parseFloat(watch('cgst')) || 0
  const sgstPercentage = parseInt(watch('sgst')) || 0
  const igstPercentage = parseFloat(watch('igst')) || 0

  const cgstAmount = (subTotal * cgstPercentage) / 100
  const sgstAmount = (subTotal * sgstPercentage) / 100
  const igstAmount = (subTotal * igstPercentage) / 100

  const totalTax = cgstAmount + sgstAmount + igstAmount
  setValue('discountAmt', discountAmt)
  setValue('itemName', itemName)
  setValue('itemCode', itemCode?.value)
  useEffect(() => {
    const calculateTotalCost = () => {
      const grandTotal = subTotal + totalTax - discountAmt
      setValue('totalAmount', grandTotal)
    }

    calculateTotalCost()
  }, [subTotal, totalTax, discountAmt])
  
  const onSubmit = async (data) => {
    try {
      if (isCreateMode) {
        dispatch(createPoStart())
        await createPo(data)
        navigate(`/po-list`)
      } else {
        const response = await updatePo(id, data)
        dispatch(updatePoSuccess({response, data}))
        navigate(`/po-list`)
      }
      // Optionally, handle success state or navigate to another page
    } catch (error) {
      console.error(error)
      // Handle error state
    }
  }
  const cancel = () => {
    navigate(`/po-list`)
  }
  return (
    <>
      <Card className='card-custom'>
        <Card.Header>
          <div className='card-title'>
            <h4 className='card-title'>{isCreateMode ? 'Create PO' : 'Edit PO'}</h4>
          </div>
          <div className='card-toolbar'>
            <Link to='/po-list' className='btn btn-primary'>
              <i className='fa-solid fa-arrow-left'></i>
              Po List
            </Link>
          </div>
        </Card.Header>

        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Row>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Vendor Company</Form.Label>
                  <Select
                    value={selectedVendor}
                    onChange={handleVendorChange}
                    options={vendorName} // Make sure vendorNames is properly populated and in the correct format
                    name='vendor'
                  />
                  {errors.vendor && <span className='text-danger'>Vendor is required</span>}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Purchase Date</Form.Label>
                  <Form.Control type='date' {...register('purchaseDate', {required: true})} />
                  {errors.purchaseDate && (
                    <span className='text-danger'>Purchase Date is required</span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Delivery Date</Form.Label>
                  <Form.Control type='date' {...register('deliveryDate', {required: true})} />
                  {errors.purchaseDate && (
                    <span className='text-danger'>Delivery Date is required</span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Order By</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Email'
                    {...register('orderBy', {required: true})}
                  />
                  {errors.orderBy && <span className='text-danger'>Order By is required</span>}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter Email'
                    {...register('email', {required: true})}
                  />
                  {errors.email && <span className='text-danger'>Email By is required</span>}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control type='tel' {...register('mobile', {required: true})} />
                  {errors.mobile && <span className='text-danger'>Mobile By is required</span>}
                </Form.Group>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Item Code</Form.Label>
                  <Select
                    value={itemCode}
                    onChange={handleItemChange}
                    options={optionsCode}
                    name='itemCode'
                  />
                  {errors.itemCode && <span className='text-danger'>Item Code By is required</span>}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Item Name</Form.Label>
                  <Form.Control type='text' value={itemName} {...register('itemName')} />
                  {errors.itemName && <span className='text-danger'>Item Name By is required</span>}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Unit</Form.Label>
                  <Select
                    value={selectedUnit}
                    onChange={handleUnit}
                    options={optionsUnit}
                    name='unit'
                  />
                  {errors.unit && <span className='text-danger'>Unit By is required</span>}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Qty</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue=''
                    placeholder='Enter Qty'
                    {...register('qty', {required: true})}
                  />
                  {errors.qty && <span className='text-danger'>Qty By is required</span>}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Rate</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue=''
                    placeholder='Enter Rate'
                    {...register('rate', {required: true})}
                  />
                  {errors.rate && <span className='text-danger'>Rate is required</span>}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <InputGroup className='mb-3'>
                  <Form.Label className='w-100'>Discount %</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue='0'
                    placeholder='Enter Discount %'
                    {...register('discount')}
                  />
                  {errors.discount && <span className='text-danger'>Discount is required</span>}
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup className='mb-3'>
                  <Form.Label className='w-100'>Discount Amt</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue='0'
                    placeholder='Enter Discount Amt'
                    {...register('discountAmt')}
                  />
                  {errors.discountAmt && <span className='text-danger'>Discount is required</span>}
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup className='mb-3'>
                  <Form.Label className='w-100'>CGST</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue='9.00'
                    placeholder='Enter CGST'
                    {...register('cgst', {required: true})}
                  />
                  {errors.cgst && <span className='text-danger'>CGST is required</span>}
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup className='mb-3'>
                  <Form.Label className='w-100'>SGST</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue=' 9.00'
                    placeholder='Enter SGST'
                    {...register('sgst', {required: true})}
                  />
                  {errors.sgst && <span className='text-danger'>SGST is required</span>}
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col lg={3}>
                <InputGroup className='mb-3'>
                  <Form.Label className='w-100'>IGST</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue=' 9.00'
                    placeholder='Enter IGST'
                    {...register('igst', {required: true})}
                  />
                  {errors.igst && <span className='text-danger'>IGST is required</span>}
                  <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Total Amount</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Enter Total Amount'
                    {...register('totalAmount', {required: true})}
                  />
                  {errors.totalAmount && (
                    <span className='text-danger'>Total Amount is required</span>
                  )}
                </Form.Group>
              </Col>
              <Col lg={3}>
                <Form.Group className='mb-3'>
                  <Form.Label>Payment Mode</Form.Label>
                  <Select
                    value={paymentName}
                    onChange={handlePaymentChange}
                    options={optionsPayment}
                    name='paymentName'
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer className='d-flex justify-content-end'>
            <Button type='submit' variant='btn btn-primary' className='me-3'>
              <i className='bi bi-send fs-4 me-2'></i>
              {isCreateMode ? 'Submit' : 'Update'}
            </Button>{' '}
            <Button type='button' variant='btn btn-danger' onClick={() => cancel()}>
              <i className=' bi bi-x-circle  fs-4 me-2'></i> Cancel
            </Button>
          </Card.Footer>
        </Form>
      </Card>
    </>
  )
}

export default PurchaseOrderPage
