import {Card} from 'react-bootstrap'
import Table from '../../../_metronic/layout/components/table'
function OutwardList() {
  return (
    <>
      <Card className='card-custom'>
        <Card.Header>
          <div className='card-title'>
            <h4 className='card-title'>Outward List</h4>
          </div>
          <div className='card-toolbar'>
            <button type='button' className='btn btn-sm btn-light'>
              Action
            </button>
          </div>
        </Card.Header>
        <Card.Body>
          <Table />
        </Card.Body>
      </Card>
    </>
  )
}

export default OutwardList
