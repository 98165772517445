/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useIntl } from "react-intl";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenuMain = () => {
  const intl = useIntl();

  return (
    <>
      <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      />
      <SidebarMenuItemWithSub
        to="/inward-list"
        title="Purchase"
        icon="element-7"
        fontIcon="bi-cart"
      >
        <SidebarMenuItem to="/item-list" title="Item List" hasBullet={true} />
        <SidebarMenuItem
          to="/vendor-list"
          title="Vendor List"
          hasBullet={true}
        />
          <SidebarMenuItem
          to="/po-list"
          title="Purchase Order List"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/inward-list"
          title="Inward Stock List"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="/outward-list"
        title="Out Ward"
        icon="element-7"
        fontIcon="bi-cart"
      >
        <SidebarMenuItem
          to="/create-outward"
          title="Create Outward"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/outward-list"
          title="Outward List"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/createProduct"
          title="Create Product"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/productList"
          title="Product List"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
    </>
  );
};

export { SidebarMenuMain };
