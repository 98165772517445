import React, {useCallback, useEffect, useState} from 'react'
import {Card} from 'react-bootstrap'
import {DataTable, Column, InputText, Dialog, Button} from 'primereact'
import {getItem, deleteItem} from '../../../redux/api' // Assuming deleteItem is a function for deleting items
import {Link, useNavigate} from 'react-router-dom'

function ItemList() {
  const [tableData, setTableData] = useState([])
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [selectedItem, setSelectedItem] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [filters, setFilters] = useState({
    global: {
      value: '',
    },
  })

  const fetchTableData = useCallback(async () => {
    try {
      const res = await getItem()
      setTableData(res)
    } catch (error) {
      console.error('Error fetching table data:', error)
    }
  }, [])

  useEffect(() => {
    fetchTableData()
  }, [fetchTableData])

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = filters ? {...filters} : {global: {value: ''}}
    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const handleShow = (rowData) => {
    setSelectedItem(rowData)
    setShowModal(true)
  }

  const handleDelete = async (rowData) => {
    try {
      await deleteItem(rowData.id)
      setTableData(tableData.filter((item) => item.id !== rowData.id))
    } catch (error) {
      console.error('Error deleting item:', error)
    }
  }
  const navigate = useNavigate()
  const handleEdit = (rowData) => {
    const itemId = rowData.id
    console.log(itemId, 'itemId')
    navigate(`/item-list/edit-item/${itemId}`)
    setSelectedItem(rowData)
  }
  const header = (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='fa-solid fa-magnifying-glass'></i>
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder='Keyword Search'
        />
      </span>
    </div>
  )

  // Apply filtering logic to tableData
  const filteredData = tableData.filter((item) => {
    return (
      item.itemName.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
      item.itemCode.toLowerCase().includes(globalFilterValue.toLowerCase())
    )
  })

  return (
    <Card className='card-custom'>
      <Card.Header>
        <div className='card-title'>
          <h4 className='card-title'>Item List</h4>
        </div>
        <div className='card-toolbar'>
          <Link to='/item-list/add-item' className='btn btn-primary'>
            <i className='fa-solid fa-plus'></i>
            Create Item
          </Link>
        </div>
      </Card.Header>
      <Card.Body>
        <DataTable
          value={filteredData}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{minWidth: '50rem'}}
          header={header}
        >
          <Column field='itemName' sortable header='Item Name'></Column>
          <Column field='itemCode' sortable header='Item Code'></Column>
          <Column header='Actions' body={actionTemplate}></Column>
        </DataTable>
      </Card.Body>
      {/* Modal for showing item details */}
      <Dialog visible={showModal} onHide={() => setShowModal(false)}>
        <div className='modal-content'>
          <h4>Item Details</h4>
          {selectedItem && (
            <div>
              <p>
                <strong>Name:</strong> {selectedItem.itemName}
              </p>
              <p>
                <strong>Code:</strong> {selectedItem.itemCode}
              </p>
              {/* Add more details here */}
            </div>
          )}
        </div>
      </Dialog>
    </Card>
  )

  // Custom template for Action column
  function actionTemplate(rowData) {
    return (
      <div className='flex'>
        <Button
          className='btn btn-light-primary me-2 px-2 py-1'
          onClick={() => handleEdit(rowData)}
        >
          <i className='fa-solid fa-pen-to-square p-0'></i>
        </Button>
        <Button className='btn btn-light-info me-2 px-2 py-1' onClick={() => handleShow(rowData)}>
          <i className='fa-regular fa-eye p-0'></i>
        </Button>
        <Button
          className='btn btn-light-danger px-2 py-1px-2 py-1'
          onClick={() => handleDelete(rowData)}
        >
          <i className='fa-regular fa-trash-can p-0'></i>
        </Button>
      </div>
    )
  }
}

export default ItemList
