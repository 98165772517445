import {Card, Col, Row} from 'react-bootstrap'
import {DataTable, Column, InputText, Dialog, Button} from 'primereact'
import {Link, useNavigate} from 'react-router-dom'
import {useCallback, useEffect, useState} from 'react'
import {deleteInward, getInward} from '../../../redux/api'

function InwardList() {
  const [tableData, setTableData] = useState([])
  const [globalFilterValue, setGlobalFilterValue] = useState('')
  const [selectedItem, setSelectedItem] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [filters, setFilters] = useState({
    global: {
      value: '',
    },
  })
  const fetchTableData = useCallback(async () => {
    try {
      const res = await getInward()
      setTableData(res)
    } catch (error) {
      console.error('Error fetching table data:', error)
    }
  }, [])

  useEffect(() => {
    fetchTableData()
  }, [fetchTableData])

  const onGlobalFilterChange = (e) => {
    const value = e.target.value
    let _filters = filters ? {...filters} : {global: {value: ''}}
    _filters['global'].value = value
    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const handleShow = (rowData) => {
    setSelectedItem(rowData)
    setShowModal(true)
  }

  const handleDelete = async (rowData) => {
    try {
      await deleteInward(rowData.id)
      setTableData(tableData.filter((item) => item.id !== rowData.id))
    } catch (error) {
      console.error('Error deleting item:', error)
    }
  }
  const navigate = useNavigate()
  const handleEdit = (rowData) => {
    const itemId = rowData.id
    navigate(`/inward-list/edit-inward/${itemId}`)
    setSelectedItem(rowData)
  }
  const header = (
    <div className='flex justify-content-end'>
      <span className='p-input-icon-left'>
        <i className='fa-solid fa-magnifying-glass'></i>
        <InputText
          value={globalFilterValue}
          onChange={onGlobalFilterChange}
          placeholder='Keyword Search'
        />
      </span>
    </div>
  )

  // Apply filtering logic to tableData
  const filteredData = tableData.filter((item) => {
    return (
      item.invoiceNumber.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
      item.itemName.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
      item.receivedBy.toLowerCase().includes(globalFilterValue.toLowerCase()) ||
      item.vendor.toLowerCase().includes(globalFilterValue.toLowerCase())
    )
  })
  return (
    <Card className='card-custom'>
      <Card.Header>
        <div className='card-title'>
          <h4 className='card-title'>Inward List</h4>
        </div>
        <div className='card-toolbar'>
          <Link to='/inward-list/create-inward' className='btn btn-primary'>
            <i className='fa-solid fa-plus'></i>
            Create Inward
          </Link>
        </div>
      </Card.Header>
      <Card.Body>
        <DataTable
          value={filteredData}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{minWidth: '50rem'}}
          header={header}
        >
          <Column field='invoiceNumber' sortable header='Invoice Number'></Column>
          <Column field='itemName' sortable header='Item Name'></Column>
          <Column field='quantityReceived' sortable header='Quantity Received'></Column>
          <Column field='unitCost' sortable header='Unit Cost'></Column>
          <Column field='totalCost' sortable header='Total Cost'></Column>
          <Column field='vendor' sortable header='Vendor'></Column>
          <Column field='receivedBy' sortable header='Received By'></Column>
          <Column header='Actions' body={actionTemplate}></Column>
        </DataTable>
      </Card.Body>
      <Dialog
        visible={showModal}
        maximizable
        style={{width: '50vw'}}
        onHide={() => setShowModal(false)}
      >
        <div className='modal-content'>
          <h4>Vendor Details</h4>
          {selectedItem && (
            <>
              <Row>
                <Col md={4}>
                  <p>
                    <strong>Name of Company:</strong> {selectedItem.nameOfCompany}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Vendor Name:</strong> {selectedItem.vendorName}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Address:</strong> {selectedItem.surveyNo},{selectedItem.landmark},
                    {selectedItem.village},{selectedItem.taluka},{selectedItem.district},<br />
                    {selectedItem.state},{selectedItem.country},{selectedItem.postalCode}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Currency:</strong> {selectedItem.currency}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Company Registration #:</strong> {selectedItem.companyRegistration}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>GSTIN No #:</strong> {selectedItem.gst}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>PAN No #:</strong> {selectedItem.pan}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Email:</strong> {selectedItem.email}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Mobile:</strong> {selectedItem.mobile}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Tel:</strong> {selectedItem.tel}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Website:</strong> {selectedItem.website}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Contact Person Name:</strong> {selectedItem.contanctPersonName}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Contact Person Designation:</strong>{' '}
                    {selectedItem.contactPersonDesignation}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Contact Person Number:</strong> {selectedItem.contactPersonNumber}
                  </p>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>
                  <p>
                    <strong>Nature of Business (Please Tick any One):</strong>{' '}
                    {selectedItem.natureOfBusiness}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Year Of Establishment:</strong> {selectedItem.yearOfEstablishment}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Name of Account Holder:</strong> {selectedItem.accountHolder}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Bank Name:</strong> {selectedItem.bankName}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Bank address:</strong> {selectedItem.bankAddress}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>IFSC:</strong> {selectedItem.ifsc}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Bank Account Number:</strong> {selectedItem.bankAccountNumber}
                  </p>
                </Col>
                <Col md={4}>
                  <p>
                    <strong>Account Type:</strong> {selectedItem.accountTypeData}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </div>
      </Dialog>
    </Card>
  )
  // Custom template for Action column
  function actionTemplate(rowData) {
    return (
      <div className='flex'>
        <Button
          className='btn btn-light-primary me-2 px-2 py-1'
          onClick={() => handleEdit(rowData)}
        >
          <i className='fa-solid fa-pen-to-square p-0'></i>
        </Button>
        <Button className='btn btn-light-info me-2 px-2 py-1' onClick={() => handleShow(rowData)}>
          <i className='fa-regular fa-eye p-0'></i>
        </Button>
        <Button
          className='btn btn-light-danger px-2 py-1px-2 py-1'
          onClick={() => handleDelete(rowData)}
        >
          <i className='fa-regular fa-trash-can p-0'></i>
        </Button>
      </div>
    )
  }
}

export default InwardList
