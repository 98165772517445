export const optionsUnit = [
  {value: 'KGS', label: 'KGS'},
  {value: 'Liter', label: 'Liter'},
  // {value: 'Nos.', label: 'Nos.'},
]

export const optionsPayment = [
  {value: 'cash', label: 'Cash'},
  {value: 'cheque', label: 'Cheque'},
  {value: 'transfer', label: 'Transfer'},
]

export const accountType = [
  {value: 'SavingsAccount', label: 'Savings Account'},
  {value: 'CurrentAccount', label: 'Current Account'},
  {value: 'NRIAccount', label: 'NRI Account'},
]
