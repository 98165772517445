import {createSlice} from '@reduxjs/toolkit'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {addItem, deleteItem, getItem, showItem, updateItem} from '../api'

export const itemSlice = createSlice({
  name: 'item',
  initialState: {
    status: 'idle',
    error: null,
    response: null,
    isLoading: false,
  },
  reducers: {
    setItemResponse(state, action) {
      state.response = action.payload
    },
    setLoading(state, action) {
      state.isLoading = action.payload
    },
    createItemStart(state) {
      state.status = 'loading'
      state.error = null
    },
    createItemSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    createItemFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    deleteItemStart(state) {
      state.status = 'loading'
      state.error = null
    },
    deleteItemSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    deleteItemFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    updateItemStart(state) {
      state.status = 'loading'
      state.error = null
    },
    updateItemSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    updateItemFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
    showItemStart(state) {
      state.status = 'loading'
      state.error = null
    },
    showItemSuccess(state, action) {
      state.status = 'succeeded'
      state.response = action.payload.response
    },
    showItemFailure(state, action) {
      state.status = 'failed'
      state.error = action.payload
    },
  },
})

export const {
  setItemResponse,
  setLoading,
  createItemStart,
  createItemSuccess,
  createItemFailure,
  deleteItemStart,
  deleteItemSuccess,
  deleteItemFailure,
  updateItemStart,
  updateItemSuccess,
  updateItemFailure,
  showItemStart,
  showItemSuccess,
  showItemFailure,
} = itemSlice.actions

export const useCreateItemItemMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(addItem, {
    onSuccess: () => {
      queryClient.invalidateQueries('item')
    },
  })
}
export const useItems = () => {
  return useQuery('item', () => getItem())
}

export const useDeleteItemMutation = () => {
  const queryClient = useQueryClient()

  return useMutation(deleteItem, {
    onSuccess: () => {
      queryClient.invalidateQueries('item')
    },
  })
}
export const useUpdateItemMutation = () => {
  const queryClient = useQueryClient()

  return useMutation((data) => updateItem(data.id, data.ItemData), {
    onSuccess: () => {
      queryClient.invalidateQueries('item')
    },
  })
}

export const useShowItemQuery = (id) => {
  return useQuery(['showItem', id], () => showItem(id))
}
export const selectResponseItem = (state) => state.item.response
export const selectIsLoading = (state) => state.item.isLoading

export default itemSlice.reducer
